import React, { useState } from 'react';
import { useSelector, } from 'react-redux';
// import * as MANAGER_CONST from '../../../constaints/ManagerReport/managerReport';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import NumberFormat from 'react-number-format';
import ManSumBreakFast from './ManSumBreakFast';
import { changeStringVNDtoNumber } from '../../../utils/Currency/SupportCurrency';

import './sumTableItem.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import ManSumRotenburo from './ManSumRotenburo';
import ManSumShowSmallModalDetails from './ManSumShowSmallModalDetails';

ManagerSumTableSubOtherServer.propTypes = {
};

const VALUE_RENDER = {
    miniBar: {
        title: 'Mini Bar',
        customers: 'MiniBar_customers',
        note: 'Minibar_note',
        sale_vnd: 'MiniBar_sale_vnd',
        sale_usd: 'MiniBar_sale_usd',
        background: 'bg_green_leaves_color'
    },
    laundry : {
        title: 'Laundry',
        customers: 'Laundry_customers',
        note: 'Laundry_note',
        sale_vnd: 'Laundry_sale_vnd',
        sale_usd: 'Laundry_sale_usd',
        background: 'bg_cloud_color'
    },
    pickUp : {
        title: 'Pick Up',
        customers: 'PickUp_customers',
        note: 'PickUp_note',
        sale_vnd: 'PickUp_sale_vnd',
        sale_usd: 'PickUp_sale_usd',
        background: 'bg_green_leaves_color'
    },
    dropOff : {
        title: 'Drop Off', 
        customers: 'DropOff_customers',
        note: 'DropOff_note',
        sale_vnd: 'DropOff_sale_vnd',
        sale_usd: 'DropOff_sale_usd',
        background: 'bg_cloud_color'
    },
    rentCar : {
        title: 'Rental car', 
        customers: 'RentalCar_customers', 
        note: 'RentalCar_note', 
        sale_vnd: 'RentalCar_sale_vnd', 
        sale_usd: 'RentalCar_sale_usd', 
        background: 'bg_green_leaves_color'
    },
    other: {
        title: 'Other',
        customers: 'Other_customers',
        note: 'Other_note',
        sale_vnd: 'Other_sale_vnd',
        sale_usd: 'Other_sale_usd',
        background: 'bg_cloud_color'
    }
}

function ManagerSumTableSubOtherServer(props) {
    const { branchID, serviceRate, area } = props;
    const manMakeSumData = useSelector(state => state.manMakeSum);
    const { edit } = manMakeSumData;
    //console.log("area", area)
    const numberRoom = manMakeSumData.information.room;
    const Tables = useSelector(state => state.manMakeSum.content);
    const [openModal, setOpenModal] = useState("");
    const totalCustomers = changeStringVNDtoNumber(Tables.BF_customers) 
         + changeStringVNDtoNumber(Tables.BF_set_customers)  
         + changeStringVNDtoNumber(Tables.BF_free)  
         + changeStringVNDtoNumber(Tables.Roten_customers) 
         + changeStringVNDtoNumber(Tables.Roten_fer_customers) 
         + changeStringVNDtoNumber(Tables.Roten_set)  
         + changeStringVNDtoNumber(Tables.Roten_fer_set)
         + changeStringVNDtoNumber(Tables.Roten_buffet) // branch AZHP
         + changeStringVNDtoNumber(Tables.Roten_free) 
         + changeStringVNDtoNumber(Tables.Roten_fer_free)
         + changeStringVNDtoNumber(Tables.rotenJPS) 
         + changeStringVNDtoNumber(Tables.rotenNJPS)
         + changeStringVNDtoNumber(Tables.rotenM100) 
         + changeStringVNDtoNumber(Tables.rotenM70)
         + changeStringVNDtoNumber(Tables.rotenM40)
         + changeStringVNDtoNumber(Tables.Private_customer)
         + changeStringVNDtoNumber(Tables.MiniBar_customers) 
         + changeStringVNDtoNumber(Tables.PickUp_customers)
         + changeStringVNDtoNumber(Tables.DropOff_customers)
         + changeStringVNDtoNumber(Tables.RentalCar_customers)
         + changeStringVNDtoNumber(Tables.Laundry_customers) 
         + changeStringVNDtoNumber(Tables.Other_customers)
         + changeStringVNDtoNumber(Tables.Roten_set);
    
    function renderInput(target) {
        return <div style={{position:'relative'}}><input
                    disabled={!edit}
                    className="op-text-red"
                    type="number"
                    
                    onBlur={e => props.onUpdateItem(e.target.value, '', target)}
                    value = {target in Tables ? Tables[target] : 0}
                    onChange={e => props.onUpdateItem(e.target.value, '', target)}
                    />
                    {/* <i className='' style={{
                        position:'absolute', 
                        color: 'blue', 
                        cursor: 'pointer', 
                        right: '15px', 
                        display: (target.includes("BF") || target.includes("Roten" || target.includes("massage"))) ? "noset" :"none"
                    }}
                    onClick={() => {
                        if(openModal === "") setOpenModal(target);
                        else if(openModal === target) setOpenModal("");
                        else if(openModal !== target) setOpenModal(target);
                    }}
                    >Details</i> */}
                    {openModal === target && <div style={{position:'absolute', color: 'blue', cursor: 'pointer', right: '-250px', top: '-8px', zIndex: '10', background: '#eeeeb0'}}>
                        <ManSumShowSmallModalDetails 
                            data={{
                                target: target in Tables ? Tables[target] : 0
                            }}/>
                    </div>}
            </ div>
    }

    function renderTextArea(target) {
        return <textarea
            style={{ width: '100%', height: '100%' }}
            disabled={!edit}
            placeholder={"note here (" + target + ")"}
            className="op-text-red"
            type="text"
            onBlur={e => props.onUpdateItem(e.target.value, '', target)}
            value = {target in Tables ? Tables[target] : ""}
            onChange={e => props.onUpdateItem(e.target.value, '', target)}
            />
    }

    function renderInputNumber(target, currency) {
        return <NumberFormat
            disabled={!edit}
            className="op-text-red"
            thousandSeparator={true}
            prefix={currency}
            value={target in Tables ? Tables[target] : 0}
            onChange={e => props.onUpdateItem(e.target.value, '', target)}
        ></NumberFormat>
    }

    function renderOneRowWithTwoRow({title, customers, note, sale_vnd, sale_usd, background}) {
        return (
            <>
                <tr>
                    <td >&nbsp;</td>
                    <td rowspan="2" className={"font-600 " + background}>{title}</td>
                    <td># of Customers:    </td>
                    <td>{renderInput(customers)}</td>
                    <td>&nbsp;</td>
                    <td rowspan="2">{renderTextArea(note)}</td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                    <td>Sales:</td>
                    <td className={branchID === 11 ? "op-hidden" : ""}>{renderInputNumber(sale_vnd)}</td>
                    <td>{renderInputNumber(sale_usd, '$')}</td>
                </tr>
            </>
        )
    }

    return (
        <React.Fragment>
            {/* OTHER SERVICE */}
            <tr className="rp_row-fix p-0">
                <td className="rp__size-m font-800 font-size-1_5rem" colspan="2">
                    {"<Other Service>"}</td>

                <td className="rp__size-l bg_pink_color">
                    Capacity Utilization
                </td>

                <td className="rp__size-l bg_pink_color">{Math.round((totalCustomers / numberRoom) * 10000) / 100 + '%'}</td>
                <td className="rp__size-l" > </td>
                <td className="rp__size-l"></td>
            </tr>

            <tr>
                <td rowspan="2" className="font-600">Total</td>
                <td rowspan="2"># of Customers:  </td>
                <td>{totalCustomers}</td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td className=" bg_orange_color">TOTAL SALES:</td>
                <td className={"bg_yellow"}>{branchID === 11 ? "$" + Number(props.otherTotalUsd).toLocaleString() : Number(props.otherTotalVnd).toLocaleString()}</td>
                <td className="bg_ocean_color">{branchID === 11 ? "" : "$" + Number(props.otherTotalUsd).toLocaleString()}</td>
                <td className="mng_rp-bill" rowspan="1" style={{minWidth:'300px', maxWidth:'300px'}}>
                    {/* rp__size-xl  */}
                    Bill Number / Note
                    <FontAwesomeIcon icon={faInfoCircle} className="mng_rp-bill-icon ml-2" />
                    <div className="mng_rp-bill_sub">
                        <p>{"Single bill : 1,2,3"}</p>
                        <p>{"Multi bill: 1->3"}</p>
                    </div>
                </td>
            </tr>
            {/* BreakFast*/}
            {(area === "Ha Noi" || branchID === 7 || branchID === 13) ? <>
                <ManSumBreakFast 
                    area = {area}
                    renderInput = {renderInput}
                    renderTextArea = {renderTextArea}
                    renderInputNumber={renderInputNumber}
                    branchID={branchID}
                    serviceRate={serviceRate}
                    Tables={Tables}
                />
            </> : 
            <>
                <tr>
                    <td >&nbsp;</td>
                    <td rowspan="3" className="font-600 bg_orange_color">BreakFast</td>
                    <td># of Customers:</td>
                    <td>  {renderInput('BF_customers')} </td>
                    <td>&nbsp;</td>
                    <td rowspan="3">{renderTextArea('BF_note')}</td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                    <td>Sales:{serviceRate.break_fast.toLocaleString()}</td>
                    {(branchID === 1 || branchID === 4 || branchID === 9) ?
                        <td>{renderInputNumber('BF_sale_vnd')}</td> :
                        branchID === 11 ? "" :
                            <td><p>{Number(Tables.BF_customers * serviceRate.break_fast).toLocaleString()}</p></td>}
                    <td>{renderInputNumber('BF_sale_usd', '$')}</td>
                </tr>
                <tr>
                    <td>&nbsp;</td>
                    <td>FREE</td>
                    <td> {renderInput('BF_free')} </td>
                    <td>&nbsp;</td>
                </tr>
            </>
            }

            {/* Rotenburo */}
            {
            //branchID === 11 ? renderRotenburoBranchAZPP() : 
            
            <ManSumRotenburo 
                   branchID={branchID}
                   Tables={Tables}
                   renderTextArea={renderTextArea}
                   renderInputNumber={renderInputNumber}
                   renderInput={renderInput}
                   serviceRate={serviceRate}
                />}

            <tr>
                <td>&nbsp;</td>
                <td>FREE</td>
                <td>{renderInput('Private_free')}</td>
                <td>&nbsp;</td>
            </tr>

            { renderOneRowWithTwoRow(VALUE_RENDER.miniBar) }
            { renderOneRowWithTwoRow(VALUE_RENDER.laundry) }
            {/* { renderOneRowWithTwoRow(VALUE_RENDER.pickUp) }
            { renderOneRowWithTwoRow(VALUE_RENDER.dropOff) } */}
            { renderOneRowWithTwoRow(VALUE_RENDER.rentCar) }
            { renderOneRowWithTwoRow(VALUE_RENDER.other) }

        </React.Fragment >
    );
}

export default ManagerSumTableSubOtherServer;