import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';

// Component
import ModalSentReport from '../../components/RCSumTable/ModalContent/ModalSentReport';
// import ModalSumCancel from '../../components/RCSumTable/ModalContent/ModalSumCancel';
import ModalSumSave from '../../components/RCSumTable/ModalContent/ModalSumSave';
import ModalEditSum from '../../components/RCSumTable/ModalContent/ModalEditSum';
import ModalNotify from '../../features/ModalNotify';
import MNGSumListDataReport from '../../components/ManagerSumTable/ManagerSumListDataReport';
import ManagerSumTableItems from '../../components/ManagerSumTable/ManagerSumTableItems';
import RCSumDropDownSelectBranch from '../../components/RCSumTable/RCSumDropDownSelectBranch';
import RCSumShowVersion from '../../components/RCSumTable/RCSumShowVersion';
import NoteReport from '../../features/NotReport';

// -- -action / redux 
import { showLoading, hideLoading } from '../../actions/ui';
import { changeHeader } from '../../actions/header';
import { actModalChange, actComponentsModal, actModalIsOk, actResetModal } from '../../actions/modal';
import { datePickup, reportManager, sumTable } from '../../redux/actions'

//--- support / const
import * as SupportDate from '../../utils/Date/SupportDate';
//--- api --
import * as managerApi from '../../api/managerReport';
import * as branchApi from '../../api/branchinfor';

import './webcome.css';
import FooterButton from '../../utils/FooterButton';

ManagerMakeContainer.propTypes = {
};

function ManagerMakeContainer() {
    let history = useHistory();
    const dispatch = useDispatch();

    const manMakeSumData = useSelector(state => state.manMakeSum);
    const sumData = manMakeSumData.content;
    const { isLoading, edit } = manMakeSumData;
    const dayMakeSum = useSelector(state => state.datepickup.mansummake);
    const branchIdOnState = useSelector(state => state.datepickup.branchIDManView)
    const token = useSelector(state => state.token);
    const auth = useSelector(state => state.auth);
    const { isManage } = auth;
    const { branchID, branch, role, area, access } = auth.user;
    const useMakeSumBySup = access['MAN SUM'] === "/manager_sum";

    // const branchInfor = useSelector(state => state.branchInformation);
    // const serviceRate = branchInfor.serviceRate;
    const serviceRate = manMakeSumData.information.serviceRate;

    const isMultiBranch = (role === 6 || role === 7 || role === 1);
    var [selectArea, setSelectArea] = useState("Select Area");
    var [selectBranch, setSelectBranch] = useState("Select Branch");
    const [daySelected, setDaySelected] = useState(0);
    const [listVersion, setListVersion] = useState({});
    const [isShowVersionList, setShowVersionList] = useState(false);
    const [isShowDataList, setShowDataList] = useState(false);
    const { branchReport, dayReport, versionReport } = useParams();
    //render AM, CEO
    const allBranchInform = useSelector(state => state.allBranchInformation);
    const listArea = allBranchInform === undefined ? [] : Object.keys(allBranchInform);
    var listBranch = (role === 6) ? renderListBranch(area) : (role === 7) ? renderListBranch(selectArea) : [];

    var expectedItem = {}
    var [listReportData, setListReportData] = useState([])
    // When begin -> select Header Force
    useEffect(() => {
        dispatch(changeHeader("/manager_sum"));
        // auto select when branch list have 1 item
        if (role === 6 && listBranch.length === 1) {
            setSelectBranch(listBranch[0]);
            // change BranchID
            const areaFindIndex = selectArea === "Select Area" ? area : selectArea;
            const findindex = allBranchInform[areaFindIndex].findIndex(({ name }) => name === listBranch[0]);
            let branchIdSelect = allBranchInform[areaFindIndex][findindex].branchID;;
            dispatch(datePickup.changeBranchIdManView(branchIdSelect));
        }

        const queryParams = window.location.href;
        if (String(queryParams).length > 50) {
            // B.M && Sup can make Sum
            if (role === 2 || (useMakeSumBySup && role === 3)) {
                if (branchID !== Number(branchReport)) {
                    alert("Mistake Branch");
                    history.push("/manager_sum");
                } else loadDataFormParam();
            } else if (role === 6) {
                loadDataFormParam();
            } else if (role === 1 || role === 7) {
                loadDataFormParam();
            }
        } else {
            const today = SupportDate.getToday();
            if (dayMakeSum === 0) {
                setDaySelected(today);
            } else if (daySelected === 0) {
                setDaySelected(dayMakeSum);
            }
            // if click back when finish load Data -> setup again Selected Branch 
            if (selectBranch === "Select Branch" && manMakeSumData.isLoading === false && isMultiBranch) {
                setSelectBranch(branchIdToName());
            }

            // if click back when finish load Data -> load again version Report 
            if (manMakeSumData.loading === false) {
                // load all version Sum this day
                const loadListVersion = async () => {
                    const resList = await managerApi.getNumberVersion(
                        (isMultiBranch ? branchIdOnState : branchID)
                        , dayMakeSum
                        , token);
                    if (resList.data.msg !== "null") { setListVersion(resList.data); }
                }
                loadListVersion();
            }
        }

        const loadListDataReport = async () => {
            const month = dayMakeSum === 0 ? SupportDate.getTodayMonth() : dayMakeSum.slice(0, 7);
            const listReportData2 = await managerApi.getListManSumReport(branchID, month, token);
            setListReportData(listReportData2.data)
        }

        loadListDataReport();

    }, [auth, listBranch.length]);

    function loadDataFormParam() {
        const today = SupportDate.getToday();
        if (dayMakeSum === 0) {
            setDaySelected(today);
            dispatch(datePickup.changeManagerMakeSum(today));
            //   dispatch(data)
        } else if (daySelected === 0) {
            setDaySelected(dayMakeSum);
        }

        if (dayReport !== undefined) {
            dispatch(datePickup.changeManagerMakeSum(dayReport));
            setDaySelected(dayReport);
            dispatch(datePickup.changeBranchIdManView(branchReport));
            loadDataAPI(branchReport, dayReport, versionReport);
        }
    }

    function renderListBranch(areaValue) {
        let list = []
        if (JSON.stringify(allBranchInform) !== '{}' && areaValue !== "Select Area") {
            allBranchInform[areaValue].forEach(branch => {
                list.push(branch.name);
            })
        } else {
            list = []
        }
        return list
    }

    if (JSON.stringify(sumData) !== '{}' && edit === true) {
        window.onbeforeunload = function () {
            return 'Are you really want to perform the action?';
        }
    }

    // Modal
    function closeModal() {
        const closeModal = actResetModal();
        dispatch(closeModal);
    }

    function onSaveSum() {
        dispatch(reportManager.saveSumReportManSum())
        closeModal();
    }

    function onSentEditRequest() {
        dispatch(reportManager.changeEditReportSum())
        closeModal()
    }

    // Button 
    function onConfirmSave() {
        const changeModal = actModalChange("centered",
            "Confirm Save Report", "Save this report ?", "", "")
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);

        const changeComponent = actComponentsModal(<ModalSumSave onSaveSum={onSaveSum} closeModal={closeModal} />)
        dispatch(changeComponent);
    }

    function onConfirmEdit() {
        openModal("modal-dialog-centered", "Confirm \"Edit report\" ", "You to edit report ?. A notification send to Area Manager & up version +1 ", "Close", "OK got it")
        const changeComponent = actComponentsModal(<ModalEditSum onSentEditRequest={onSentEditRequest} closeModal={closeModal} />)
        dispatch(changeComponent);
    }

    function onConfirmSentReport() {
        const changeModal = actModalChange("centered",
            "Confirm Sent Report", "Sent this report ?", "Cancel", "OK")
        dispatch(changeModal);

        const openModal = actModalIsOk();
        dispatch(openModal);

        const changeComponent = actComponentsModal(<ModalSentReport onSentSum={onSentSum} closeModal={closeModal} onChangeNoteMail={onChangeNoteMail} />)
        dispatch(changeComponent);
    }

    function onChangeNoteMail(value) {
        dispatch(sumTable.noteSentMail(value));
    }

    function onSentSum() {
        dispatch(reportManager.sentReportSum());
        closeModal();
    }

    function openModal(type, title, content, btn1, btn2) {
        const changeModal = actModalChange(type, title, content, btn1, btn2);
        dispatch(changeModal);

        const actOpen = actModalIsOk();
        dispatch(actOpen);
    }

    function changeSelectBranchID(value) {
        // set value on screen view
        setSelectBranch(value);
        // change BranchID
        const areaFindIndex = selectArea === "Select Area" ? area : selectArea;
        const findindex = allBranchInform[areaFindIndex].findIndex(({ name }) => name === value);
        let branchIdSelect = allBranchInform[areaFindIndex][findindex].branchID;
        dispatch(datePickup.changeBranchIdManView(branchIdSelect))
    }

    function changeSelectArea(value) {
        // reset select Branch + selected BranchID when select Area 
        setSelectArea(value);
        dispatch(datePickup.changeBranchIdRCView(0)) //reset select BranchID
    }

    // API
    function loadDataAPI(brandReq, dayReq, versionReq) {
        dispatch(datePickup.changeManagerMakeSum(dayReq))

        const loadBranchNameFromID = async () => {
            const res = await branchApi.getNameBranch(brandReq, token);
            return res.data;
        }
        const loadBranchAreaFromID = async () => {
            const res = await branchApi.getAreaBranch(brandReq, token);
            return res.data;
        }

        loadBranchAreaFromID().then(resArea => {
            if (resArea !== area && role !== 7) {
                alert("Mistake Branch Area");
                return history.push("/manager_sum");
            } else {
                selectArea = resArea;
                setSelectArea(resArea)
                loadBranchNameFromID().then(resBranchName => {
                    selectBranch = resBranchName;
                    setSelectBranch(resBranchName);
                    loadData();
                });
            }
        });

        const loadData = () => {
            // check Data have report ?
            if (role === 7 && selectArea === "Select Area") {
                openModal("", "NOTIFICATION", "PLEASE SELECT AREA", "", "OK")
            }
            else if ((role >= 5) && selectBranch === "Select Branch") {
                openModal("", "NOTIFICATION", "PLEASE SELECT BRANCH", "", "OK")
            } else if (dayReq === 0) {
                openModal("", "NOTIFICATION", "PLEASE SELECT DATE SUM", "", "OK")
            }
            else {
                dispatch(reportManager.resetDataReport());
                if (versionReq !== undefined && versionReq !== "current") {
                    loadWithVersion(brandReq, dayReq, versionReq);
                } else if (versionReq === "current") {
                    if (isManage || (useMakeSumBySup && role === 3)) {
                        loadDataByManager(brandReq, dayReq)
                    } else {
                        dispatch(reportManager.getDataManagerViewSum());
                    }
                }

                // load all version Sum this day
                const loadListVersion = async () => {
                    const resList = await managerApi.getNumberVersion(brandReq, dayReq, token);
                    if (resList.data.msg !== "null") { setListVersion(resList.data) }
                }
                loadListVersion();
            }
        }
    }

    function loadDataByManager(brandReq, dayReq) {
        // check Data have report ?
        const FetchSumData = async () => {
            try {
                //     dispatch(unitsAction.changeContentLoading("Check Data on Server"));
                const response = await managerApi.getManagerReport(brandReq, dayReq, token);
                if (response.data.msg === "null") {
                    dispatch(reportManager.getDataFormRcSum());
                } else {
                    dispatch(reportManager.showLoadingSumView());
                    dispatch(reportManager.resetDataReport());
                    const branchData = await branchApi.getInform(brandReq, token);
                    dispatch(reportManager.fetchBranchInform(branchData.data[0]));
                    dispatch(reportManager.fetchDataFormReportManagerServer(response.data));
                }
            } catch (error) {
                console.log('Failed to fetch Hotel Invoice List:', error)
            }
        }
        FetchSumData();
    }

    function loadWithVersion(brandReq, dayReq, versionReq) {
        const FetchSumData = async () => {
            try {
                dispatch(showLoading());
                const response = await managerApi.getSumWithVersion(brandReq, dayReq, versionReq, token);
                dispatch(hideLoading());

                if (response.data.msg === "null") {
                    //  load data from Moja API
                    alert("Null Data this Day");
                } else if (response.data.edit === true && (!isManage || !useMakeSumBySup)) {
                    alert("Editing this report day");
                }
                else {
                    //   dispatch(reportManager.fetchVersionSum(response.data.version));
                    const branchData = await branchApi.getInform(brandReq, token);
                    dispatch(reportManager.fetchBranchInform(branchData.data[0]));
                    dispatch(reportManager.fetchDataFormReportManagerServer(response.data));
                }
            } catch (error) {
                console.log('Failed to fetch Hotel Invoice List:', error)
            }
        }
        FetchSumData();
    }

    function onUpdateItem(value, ID, target) {
        dispatch(reportManager.changeManagerReportChidden({ value, ID, target }));

        if (branchID === 11 && target === "Roten_customers") {
            console.log("serviceRate on onUpdateItem",serviceRate)
            dispatch(reportManager.changeManagerReportChidden({ value: '$' + (value * serviceRate.roten), ID, target: "Roten_sale_usd" }));
        }

        if (branchID === 11 && target === "Roten_set") {
            dispatch(reportManager.changeManagerReportChidden({ value: '$' + (value * serviceRate.roten_set), ID, target: "Roten_set_sale_usd" }));
        }
    }

    function branchIdToName() {
        if (allBranchInform[area] !== undefined) {
            const findindexDel = allBranchInform[area].findIndex(({ branchID }) => branchID === Number(branchIdOnState));
            return allBranchInform[area][findindexDel].name
        }
    }

    return (
        <React.Fragment>
            <ModalNotify closeModal={closeModal} />
                <ul className="nav nav-sub rp__title row_freeze mb-3"
                    style={{ position: 'fixed', top: '45px', zIndex: '11', }}>
                    {
                        (role === 1 || role === 7) && <RCSumDropDownSelectBranch
                            onSelect={changeSelectArea}
                            onblur={setSelectArea}
                            selectValue={selectArea}
                            listRender={listArea}
                        /> 
                    }
                    {
                        (role === 1 || role === 7 || role === 6 || role === 5) && <RCSumDropDownSelectBranch
                            onSelect={changeSelectBranchID}
                            onblur={setSelectBranch}
                            selectValue={selectBranch}
                            listRender={listBranch}
                        />
                    }
                    <li className="nav-item-sub">
                        <input
                            type="date"
                            className="nav-link-sub"
                            href="#"
                            onChange={e => setDaySelected(e.target.value)}
                            value={daySelected}
                        />
                    </li>

                    <li>
                        <Link
                            className="nav-item-sub btn_load_data"
                            id="modal_inputdata"
                            to={`/manager_sum/${isMultiBranch ? branchIdOnState : branchID}/${daySelected}/current`}
                            onClick={() => loadDataAPI((isMultiBranch ? branchIdOnState : branchID), daySelected, `current`)}
                        >Load Data  </Link>
                    </li>

                    <li>
                        <button
                            className="nav-item-sub btn_load_data"
                            onClick={() => setShowDataList(!isShowDataList)}>
                            Version / Edit</button>
                    </li>

                    <li className="ml-5">
                        <h1 className="mr-3" //rp__title row_freeze 
                            style={isMultiBranch ? { margin: '0 0', cursor: 'pointer' } : { cursor: 'pointer' }}
                            onClick={() => setShowVersionList(!isShowVersionList)}
                        >
                            {edit ? "[EDIT]" : "[VIEW]"}
                            {isMultiBranch ? selectBranch : branch} Manager Sum Report : {dayMakeSum === 0 ? "" : SupportDate.changeDataToYYYY_MMMM_DD(dayMakeSum)}
                            (Version: {manMakeSumData.version})
                        </h1>
                    </li>
                </ul>
            {/* </div> */}
            {isShowVersionList ?
                <div style={{ marginTop: '75px' }}>
                    <RCSumShowVersion
                        isMultiBranch={isMultiBranch}
                        branchIdOnState={branchIdOnState}
                        subDomain="manager_sum"
                        currentVersion={manMakeSumData.version}
                        versionReport={versionReport}
                        selectDateInput={daySelected}
                        onLoadData={loadDataAPI}
                        numberVersion={listVersion.numberVersion}
                        editOnLastVersion={listVersion.editInLastVersion}
                    />
                </div> : ""}

            {isShowDataList ? <MNGSumListDataReport
                isMultiBranch={isMultiBranch}
                month={daySelected}
                branchIdOnState={branchIdOnState}
                listReportData={listReportData}
            /> : ""}

            <div style={{ marginTop: isShowVersionList ? '20px' : '80px' }} className="container">
                {!isLoading ?
                    <ManagerSumTableItems
                        Items={sumData}
                        serviceRate={serviceRate}
                        expectedItem={expectedItem}
                        branchID={isMultiBranch ? branchIdOnState : branchID}
                        key={sumData}
                        onUpdateItem={onUpdateItem}
                    />
                    : <NoteReport />
                }
            </div>

            <div className="p-3"></div>

            {
                (!isLoading && (isManage || (useMakeSumBySup && role === 3))) ?
                    manMakeSumData.edit ? <FooterButton
                        button1="SAVE REPORT"
                        button2="SEND REPORT"
                        iconBtn1="faSave"
                        iconBtn2="faPaperPlane"
                        onClickButton1={onConfirmSave}
                        onClickButton2={onConfirmSentReport}
                    /> :
                        <FooterButton
                            button1="REQUEST EDIT"
                            iconBtn1="faEdit"
                            onClickButton1={onConfirmEdit}
                        /> : ""
            }

        </React.Fragment >
    );
}

export default ManagerMakeContainer;