import React, { useEffect, useState } from 'react';
import { loadContentAzVNByType } from '../../../../api/azumayavietnam/conentazvn';
import { useDispatch, useSelector } from "react-redux";
import { showLoading, hideLoading } from '../../../../actions/ui';

 function EditContentAzVN() {
    const token = useSelector(state => state.token);
    const dispatch = useDispatch();

    const [data, setData] = useState({
        language: "en",
        target: "header",
        subTarget1: "",
        subTarget2: "",
        subTarget3: "",
        subTarget4: "",
        subTarget5: "",
        subTarget6: "",
        content:  {},
        arrayEdit1 : [],
        arrayEdit2 : [],
        objectEdit2: {}

    });

    function onChangeContent() {

    }

    useEffect(() => {
        const loadData = async (token) => {
            dispatch(showLoading());
            const result = await loadContentAzVNByType(data.language,data.target);
            if(result) setData({...data, content: result.data.data})
            dispatch(hideLoading());
        }

        if(token) loadData(token)
    },[token,data.language,data.target])


    function renderTabEdit( ) {
        const result = [];

        const keyOnObject = Object.keys( data.content );

        if(keyOnObject.length > 0) {
            keyOnObject.forEach(key => {
                if(!Array.isArray(data.content[key])) {
                    result.push(
                        <div key={key} className='d-flex'>
                            <div style={{width:'100px', fontSize:'1.2rem', border:'1px solid'}}>{key}</div>
                            <input type="text" 
                                   style={{width: '150px'}}
                                   value={data.content[key]} 
                                   onChange={(e) => onChangeContent(e, key)}/>
                        </div>
                    )
                } else if (Array.isArray(data.content[key])) {
                    result.push(
                        <div key={key} className='d-flex' >
                            <div style={{width:'100px', fontSize:'1.2rem', border:'1px solid'}}>{key}</div>
                            <div style={{border:'1px solid', width: '150px', cursor:'pointer'}}>
                                <button style={{border:'1px solid', width: '100px', cursor:'pointer'}}
                                    className='btn btn-primary'
                                    onClick={() => setData({
                                        ...data,
                                        language: data.language,
                                        target: data.target,
                                        arrayEdit1: data.content[key],
                                    })}>Click show Array</button>
                            </div>

                        </div>
                    ) 
                }

            })
        }
        return result;
    }

    function renderTabEdit2() {
        let result = [];

        console.log("data.arrayEdit1", data.arrayEdit1)
        if(data.arrayEdit1) {
            if(data.arrayEdit1.length > 0) {
                data.arrayEdit1.forEach((item, index) => {
                    result.push(
                        <div key={index} className='d-flex'>
                            <div style={{width:'100px', fontSize:'1.2rem', border:'1px solid'}}>{index+1}</div>
                            <input type="text" 
                                   style={{width: '150px'}}
                                   value={item} 
                                   />
                        </div>
                    )
                })
            }
        }

        return result;
    }

    function renderTarget() {
        const dataRender = ["header", "home","account","memberShip","branchDetail","area","branch","checkBranch","booking","room"];
        let result = [];

        dataRender.forEach((item, index) => {
           // if(!Array.isArray(data.content[item])) {
            return result.push(<div onClick={e => setData({language: data.language, target: item, content: {}})} 
            value={item}
            key = {index}
            style={{width:"150px", fontSize:'1.3rem', padding: '4px', border:'1px solid', background: data.target === item ? "aqua" : ""}}>{item}</div>)
   
        })

        return result;
    }

    return (
        <div>
            <h1>Edit Content AZVN</h1>
            <div className='d-flex'>
                <div>
                    <div onClick={() => setData({language:"en", content: {}, target:"header"},)}
                        value = "English" 
                        style={{width:"150px", fontSize:'1.8rem', padding: '8px', border:'1px solid', cursor: 'pointer', background: data.language === "en" ? "yellow": ""}}>English</div>
                    <div onClick={() => setData({language:"ja", content: {}, target:"header"},)}
                        value = "Japan" 
                        style={{width:"150px", fontSize:'1.8rem', padding: '8px', border:'1px solid', cursor: 'pointer', background: data.language === "ja" ? "yellow": ""}}>Japan</div>
                    <div onClick={() => setData({language:"vi", content: {}, target:"header"},)}
                        value = "Viet Nam" 
                        style={{width:"150px", fontSize:'1.8rem', padding: '8px', border:'1px solid', cursor: 'pointer', background: data.language === "vi" ? "yellow": ""}}>Viet Nam</div>
                    <div onClick={() => setData({language:"ko", content: {}, target:"header"},)}
                        value = "Korea" 
                        style={{width:"150px", fontSize:'1.8rem', padding: '8px', border:'1px solid', cursor: 'pointer', background: data.language === "ko" ? "yellow": ""}}>Korea</div>
                </div>

                <div>{renderTarget()}</div>
                <div className='ml-3'> {renderTabEdit()}</div>
                <div className='ml-3'> {renderTabEdit2()}</div>

            </div>
        </div>
    );
}

export default EditContentAzVN;