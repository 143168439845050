import React, { useState, version } from 'react';
import { useSelector } from 'react-redux';

import ManagerSumTableSubHotel from '../ManagerSumTableSubHotel';
import ManagerSumTableSubMassage from '../ManagerSumTableSubMassage';
import ManagerSumTableSubOtherServer from '../ManagerSumTableSubOtherServer';
import { changeStringUSDtoNumber, changeStringVNDtoNumber } from '../../../utils/Currency/SupportCurrency';
import { LIST_CALCULATION_MASSAGE_VND, LIST_CALCULATION_MASSAGE_USE, MNG_AZPP_ROTENBURO_CHILDREN_RENDER } from '../../../constaints/ManagerReport/managerReport';

import './sumTableItem.css'
import 'bootstrap/dist/css/bootstrap.min.css';

ManagerSumTableItems.propTypes = {
};

function ManagerSumTableItems(props) {
    const manMakeSumData = useSelector(state => state.manMakeSum);
    const branchInfor = manMakeSumData.information;
    //= useSelector(state => state.branchInformation);
    const [openModal, setOpenModal] = useState("");

    const { versionCurrent = version, edit } = manMakeSumData;
    const Tables = useSelector(state => state.manMakeSum.content);
    const auth = useSelector(state => state.auth);
    const { area } = auth.user;
    const { branchID, serviceRate } = props;

    const totalProfit = branchInfor.totalProfit;
    const sumRate = branchInfor.sumRate;
    const { break_fast, roten, roten_set, roten_buffet,
        massage_100, massage_70, massage_40, massage_set, massage_set40, massage_set70, massage_set100,
        massage_70_ws, massage_100_ws, massage_set70_ws, massage_set100_ws } = serviceRate; //pick_up, drop_off, 

    const hotelTotalVnd = versionCurrent === undefined ? 0 : (changeStringVNDtoNumber(Tables.day_sale_vnd) + changeStringVNDtoNumber(Tables.late_sale_vnd) + changeStringVNDtoNumber(Tables.short_sale_vnd) + changeStringVNDtoNumber(Tables.Other_hotel_customers_vnd));
    const hotelTotalUsd = versionCurrent === undefined ? 0
        : (changeStringUSDtoNumber(Tables.day_sale_usd) + changeStringUSDtoNumber(Tables.late_sale_usd)
            + changeStringUSDtoNumber(Tables.short_sale_usd) + changeStringUSDtoNumber(Tables.Other_hotel_customers_usd));

    function calculationTotalMassageVND() {
        let total = 0;
        LIST_CALCULATION_MASSAGE_VND.forEach((item) => {
            switch (item) {
                case "massage40m_guest": total += (changeStringUSDtoNumber(Tables[item]) * changeStringUSDtoNumber(massage_40)); break;
                case "massage100m_customers": total += (changeStringUSDtoNumber(Tables[item]) * changeStringUSDtoNumber(massage_100) * 0.9); break;
                case "massage100m_guest": total += (changeStringUSDtoNumber(Tables[item]) * changeStringUSDtoNumber(massage_100)); break;
                case "massage100mWS_guest": total += (changeStringUSDtoNumber(Tables[item]) * changeStringUSDtoNumber(massage_100_ws)); break;
                case "massage100mWS_customers": total += (changeStringUSDtoNumber(Tables[item]) * changeStringUSDtoNumber(massage_100_ws) * 0.9); break;

                case "massage70m_customers": total += (changeStringUSDtoNumber(Tables[item]) * changeStringUSDtoNumber(massage_70) * 0.9); break;
                case "massage70m_guest": total += (changeStringUSDtoNumber(Tables[item]) * changeStringUSDtoNumber(massage_70)); break;
                case "massage70mWS_guest": total += (changeStringUSDtoNumber(Tables[item]) * changeStringUSDtoNumber(massage_70_ws)); break;
                case "massage70mWS_customers": total += (changeStringUSDtoNumber(Tables[item]) * changeStringUSDtoNumber(massage_70_ws) * 0.9); break;

                case "massage_set40_guest": total += (changeStringUSDtoNumber(Tables[item]) * changeStringUSDtoNumber(massage_set40)); break;
                case "massage_set70_guest": total += (changeStringUSDtoNumber(Tables[item]) * changeStringUSDtoNumber(massage_set70)); break;
                case "massage_set100_guest": total += (changeStringUSDtoNumber(Tables[item]) * changeStringUSDtoNumber(massage_set100)); break;
                case "massage_set_guest": total += (changeStringUSDtoNumber(Tables[item]) * changeStringUSDtoNumber(massage_set)); break;
                case "massage_set70WS_guest": total += (changeStringUSDtoNumber(Tables[item]) * changeStringUSDtoNumber(massage_set70_ws)); break;
                case "massage_set100WS_guest": total += (changeStringUSDtoNumber(Tables[item]) * changeStringUSDtoNumber(massage_set100_ws)); break;
                default: break
            }

        })
        return total
    }

    function calculationTotalMassageUSD() {
        let total = 0;
        LIST_CALCULATION_MASSAGE_USE.forEach((item) => {
            total += changeStringUSDtoNumber(Tables["" + item])
        })
        return total;
    }

    const massageTotalVnd = (versionCurrent === undefined) ? 0 : calculationTotalMassageVND();
    const massageTotalUsd = (versionCurrent === undefined) ? 0 : calculationTotalMassageUSD();

    const otherTotalVnd = (versionCurrent === undefined || branchID === 11) ? 0 :
        ((branchID === 9) ? changeStringVNDtoNumber(Tables.Roten_sale_vnd) + changeStringVNDtoNumber(Tables.Roten_fer_sale_vnd) : changeStringVNDtoNumber(Tables.Roten_customers * roten)) 
        + ((branchID === 13) ? changeStringVNDtoNumber(Tables.Roten_buffet * roten_buffet) : 0)
        + changeStringVNDtoNumber(Tables.Roten_set * roten_set)
        + changeStringVNDtoNumber(Tables.Private_sale_vnd)
        + changeStringVNDtoNumber(Tables.MiniBar_sale_vnd) + changeStringVNDtoNumber(Tables.Laundry_sale_vnd)
        + changeStringVNDtoNumber(Tables.RentalCar_sale_vnd) + changeStringVNDtoNumber(Tables.Other_sale_vnd)
        + ((branchID === 1 || branchID === 4 || branchID === 9) ? changeStringVNDtoNumber(Tables.BF_sale_vnd) : changeStringVNDtoNumber(Tables.BF_customers * break_fast))
        + (branchID === 8 ? 
            "roten_fer" in serviceRate ? 
               (changeStringVNDtoNumber(Tables.Roten_fer_customers *serviceRate.roten_fer) + changeStringVNDtoNumber(Tables.Roten_fer_set * serviceRate.roten_fer_set))
                : 0 :0 );

    const otherTotalUsd = versionCurrent === undefined ? 0 :
        changeStringUSDtoNumber(Tables.BF_sale_usd)
        + changeStringUSDtoNumber(Tables.BF_set_sale_usd)
        + changeStringUSDtoNumber(Tables.Roten_sale_usd)
        + changeStringUSDtoNumber(Tables.Roten_set_sale_usd)
      //  + (branchID === 13 ? "Roten_buffet_sale_usd" in Tables ? changeStringUSDtoNumber(Tables.Roten_buffet_sale_usd) : 0 : 0 )
        + (branchID === 13 ? changeStringUSDtoNumber(Tables.Roten_buffet_sale_usd) : 0 )
        + changeStringUSDtoNumber(Tables.Roten_fer_sale_usd)
        + changeStringUSDtoNumber(Tables.Roten_fet_set_sale_usd)
        + changeStringUSDtoNumber(Tables.Private_sale_usd)
        + changeStringUSDtoNumber(Tables.MiniBar_sale_usd)
        + changeStringUSDtoNumber(Tables.Laundry_sale_usd)
        + changeStringUSDtoNumber(Tables.RentalCar_sale_usd)
        + changeStringUSDtoNumber(Tables.Other_sale_usd)
        + (branchID === 11 ? checkTargetOnTablesAndCalculate() : 0)
        + ((branchID === 1 || branchID === 4 || branchID === 9) ? changeStringUSDtoNumber(Tables.BF_sale_usd) : 0);

    const sumTotalVnd = hotelTotalVnd + massageTotalVnd + otherTotalVnd;
    const sumTotalUsd =  hotelTotalUsd + massageTotalUsd + otherTotalUsd
        // branchID !== 11
        //     ? hotelTotalUsd + massageTotalVnd + otherTotalUsd
        //     : hotelTotalUsd + massageTotalUsd + otherTotalUsd;

    // function changeStringVNDtoNumber(a) {
    //     if (a === 0) {
    //         return 0
    //     } else return Number(a
    //         ? a.replace(',', '').includes(',') ? a.replace(',', '').replace(',', '') : a.replace(',', '')
    //         : 0);
    // }

    function changeNumberUsd(a) {
        return Number(a ? a.replace('$', '').replace(',', '').replace(',', '') : 0);
    }

    function checkTargetOnTablesAndCalculate() {
        let result = 0;
        MNG_AZPP_ROTENBURO_CHILDREN_RENDER.forEach(item => {
            result += ( item in Tables && item in serviceRate ) ? serviceRate[item] * Number(Tables[item]) : 0;
        })
        return result;
    }
    //grid
    return (
        <React.Fragment>
            <>
                <div >
                    <table className="rp table-scoll margin-auto">
                        <tr className="rp_row-fix p-0">
                            <td className="rp__size-m font-size-1_5rem font-600">
                                Hotel</td>
                            <td className="rp__size-l"></td>
                            <td className="rp__size-xl "> Invoice# </td>

                            <td className="rp__size-l bg_yellow" colspan="3">
                                <input disabled={!edit} value={Tables.listHI} onChange={e => props.onUpdateItem(e.target.value, '', 'listHI')} />
                            </td>
                        </tr>
                        {/* Service Number */}
                        <tr className="rp_row-fix p-0">
                            <td className="rp__size-m font-size-1_5rem font-600 ">
                                Service </td>
                            <td className="rp__size-l"></td>
                            <td className="rp__size-xl"> Invoice# </td>

                            <td className="bg_yellow" colspan="3">
                                <input disabled={!edit} value={Tables.listSI} onChange={e => props.onUpdateItem(e.target.value, '', 'listSI')} />
                            </td>
                        </tr>
                        <tr>
                            <td> </td>
                        </tr>

                        <tr className="rp_row-fix p-0">
                            <td className="rp__size-m font-size-1_5rem font-600" colspan="2">
                                Today's Sales </td>
                            <td className="rp__size-l bg_yellow"> {branchID === 11 ? "" : Number(sumTotalVnd).toLocaleString()}</td>

                            <td className="bg_ocean_color" colspan="3">
                                ${Number(sumTotalUsd).toLocaleString()}
                            </td>
                        </tr>

                        <tr className="rp_row-fix p-0">
                            <td className="rp__size-m font-size-1_5rem font-600" colspan="2">
                                Fixed Expence</td>
                            <td className="op-text-red font-size-1_5rem font-600" colspan="5">
                                {Number(totalProfit).toLocaleString()}
                            </td>
                        </tr>

                        <tr className="rp_row-fix p-0">
                            <td className="rp__size-m font-size-2rem font-600 op-text-ocean" colspan="2">
                                Total profit</td>

                            <td className=" font-size-2rem font-600 op-text-ocean " colspan="5">
                                {branchID === 11 ?
                                    Number(sumTotalUsd - totalProfit).toLocaleString() :
                                    Number((sumTotalVnd + sumTotalUsd * sumRate) - totalProfit).toLocaleString()}
                            </td>
                        </tr>

                        <br></br>

                        <ManagerSumTableSubHotel
                            branchID={branchID}
                            Tables={props.Tables}
                            key={props.Tables}
                            onUpdateItem={props.onUpdateItem}
                            hotelTotalVnd={hotelTotalVnd}
                            hotelTotalUsd={hotelTotalUsd} />
                        <br></br>
                        {((area === "Ha Noi" || branchID === 4 || branchID === 9) && branchID !== 12) ? "" :
                            <ManagerSumTableSubMassage
                                branchID={branchID}
                                onUpdateItem={props.onUpdateItem}
                                massageTotalVnd={massageTotalVnd}
                                massageTotalUsd={massageTotalUsd} />}
                        <br></br>
                        <ManagerSumTableSubOtherServer
                            branchID={branchID}
                            area={area}
                            serviceRate= {serviceRate}
                            onUpdateItem={props.onUpdateItem}
                            otherTotalVnd={otherTotalVnd}
                            otherTotalUsd={otherTotalUsd} />
                    </table>
                </div>
                <div className="mb-5"></div>
            </>
        </React.Fragment >
        // Fixed Expence
    );
}

export default ManagerSumTableItems;