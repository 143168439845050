import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

// ---- action / redux
import { actModalChange, actModalIsOk, actResetModal } from '../../../../actions/modal';
import { showLoading, hideLoading } from '../../../../actions/ui';
import { datePickup } from '../../../../redux/actions/index';

// api / support
import * as memberShip from '../../../../api/azumayavietnam/menbership';
import ModalSupportOption from '../../../../components/SupportComponents/Modal/ModalOption';

import './membership.css'
import TableShowMemberShipUser from '../../../../components/Postion/Admin/MemberShip/TableShowMemberShipUser';
import Modal2Button from '../../../../components/RCSumTable/ModalContent/Modal2Button';
import UseVoucherByReception from './UseVoucherByReception';

function MemberShip() {

    const dispatch = useDispatch();
    const token = useSelector(state => state.token);
    // const allBranchInform = useSelector(state => state.allBranchInformation);
    // const dataBranchOnState = useSelector(state => state.datepickup.selectAdminUser)
    // var [dataUser, setDataUser] = useState([]);
    // var [listVoucher, setListVoucher] = useState([]);

    var [loading, setLoading] = useState(false);
    var [data, setData] = useState([]);
    var [selectMenu, setSelectMenu] = useState("member");

    useEffect(() => {
        // const takeListUser = async () => {
        //     const result = await memberShip.takeListMemberShip(0, 1000,token);
        //     console.log("result", result)
        //     if(result) setData(result.data.data);
        // }

        if(token && selectMenu === "member") loadListMember();
        
    },[token])

    const loadListVoucher = async () => {
        setLoading(true);
        setSelectMenu("voucher")

        const result = await memberShip.takeListVoucherPushlic(token);
      //  console.log("result", result.data.data)
         if(result) setData(result.data.data);
        setLoading(false);
    }

    const loadListMember = async () => {
        setLoading(true);
        setSelectMenu("member")

        const result = await memberShip.takeListMemberShip(0, 1000,token);
        if(result) setData(result.data.data);
    
        setLoading(false);
    }

    const loadUseVoucherByReception = async () => {
        setLoading(true);
        
        setSelectMenu("useVoucherByRc");

        setLoading(false);
    }

    const checkFunction1 = (value) => {
        if(typeof value === "number") {
            if(selectMenu === "voucher") onDeleteAndEditVoucher(value, "all");
            if(selectMenu === "member") ViewListVoucherByID(value);
        }
    }

    const checkFunction2 = (value) =>{
        if(typeof value === "number") {
            if(selectMenu === "voucher") onDeleteAndEditVoucher(value);
        }
    }

    const checkFunction3 = (value) => {
        if(typeof value === "number") {
            if(selectMenu === "member") onAddNewPublicVoucher("new");
            if(selectMenu === "voucher") onAddNewPublicVoucher("new");

        }
    }

    function ViewListVoucherByID(index) {
        const loadVoucherByID = async () => {
            const result = await memberShip.takeVoucherListByUserId(data[index]._id, token);
            if(result) {
                const changeModal = actModalChange("centered", "View voucher", 
                <ModalSupportOption
                    type = {13}
                    itemOption ={{data: result.data.data }}
                    //featureButton1={() => editVoucher(data[index], "all")}
                />);

                dispatch(changeModal);
                const openModal = actModalIsOk();
                dispatch(openModal);
            }
        }

        loadVoucherByID();
    }

    function onDeleteAndEditVoucher(index, type) {
        let changeModal;

        const editVoucher = async (index, type) => {
           setLoading(true);
           const editVoucher = await memberShip.editVoucherByAdmin(type, data[index], data[index]._id, token );

           if(editVoucher) {
               loadListVoucher();
               closeModal();
           }
           setLoading(false);
        }

        if(type === "delete") {
            changeModal = actModalChange("centered", "Confirm delete: "+ data[index].name + " (code:" + data[index].code +")", 
            <Modal2Button 
                buttonName1 = "Close"
                buttonName2 = "Delete"
                styleButton1 = "btn-primary"
                styleButton2 = "btn-danger"
                onClickButton1 = {closeModal}
                onClickButton2 = {() => editVoucher(index, "delete")}
                    />)
        } else {
            changeModal = actModalChange("centered", "Edit voucher", 
            <ModalSupportOption
                type = {12}
                itemOption ={{data: data[index], selectMenu}}
                featureButton1={() => editVoucher(data[index], "all")}
            />);
        }

        dispatch(changeModal);
        const openModal = actModalIsOk();
        dispatch(openModal);
    }

    function onAddNewPublicVoucher(dataRender) {
        let changeModal;

        const onAddNewPublicVoucherSubmit = async (dataAdd) => {
            setLoading(true);

            const addVoucher = await memberShip.makeVoucherByAdmin("system", dataAdd, token )

            if(addVoucher) {
                loadListVoucher();
                closeModal();
            }

            setLoading(false);
        }      

        changeModal = actModalChange("centered", "Add new public voucher", 
            <ModalSupportOption
                type = {12}
                itemOption ={{data: dataRender, selectMenu}}
                featureButton1={onAddNewPublicVoucherSubmit}
            />);

        dispatch(changeModal);
        const openModal = actModalIsOk();
        dispatch(openModal);
    }

    function closeModal() {
        const closeModal = actResetModal();
        dispatch(closeModal);
    }

    return (
        <React.Fragment>
            <h1>Member Ship Manager</h1>
            <div className={loading ? "" : "op-hidden"}>
                <p>loading...</p>
                <img src="https://res.cloudinary.com/dsm20cmen/image/upload/v1626411475/store/loading_short_daigrr.gif" alt="loading" />
            </div>

            <div className="d-flex manager-user">
                <div className="manager-user_menu">
                    <ul className="manager-user_menu-list" style={{ listStyle: 'none' }}>
                        <li className={"manager-user_menu-item " + (selectMenu === "member" ? "select" : "")}
                            onClick={() => loadListMember("member")}>Member</li>
                        <li className={"manager-user_menu-item " + (selectMenu === "voucher" ? "select" : "")}
                            onClick={() => loadListVoucher("voucher")}>Voucher Public</li>
                        <li className={"manager-user_menu-item " + (selectMenu === "useVoucherByRc" ? "select" : "")}
                            onClick={() => loadUseVoucherByReception("useVoucherByRc")}>Voucher Use RC</li>
                    </ul>
                </div>

            {(selectMenu === "member" || selectMenu === "voucher") && <div style={{ width: '85%', marginLeft: "24px" }} 
                    className={"manager-user-content rp manager-user_branch" }>
                    <TableShowMemberShipUser 
                        typeShow= {selectMenu}
                        data={data}
                        function1 = {checkFunction1} // onDeleteAndEditVoucher + ViewListVoucherByID
                        function2 = {checkFunction2} // delete voucher
                        function3 = {checkFunction3} // add new voucher
                    />
            </div>}

            {(selectMenu === "useVoucherByRc") && <div style={{ width: '85%', marginLeft: "24px" }} 
                    className={"manager-user-content rp manager-user_branch" }>
                    {/* TODO */}
                    {/* <h1>User Voucher by Reception</h1> */}
                    <UseVoucherByReception 
                        typeShow= {selectMenu}

                     />
            </div>}
            </div>
        </React.Fragment >
    );
}

export default MemberShip;