import axios from 'axios';

export const takeListMemberShip = (page, limit, token) => {
    let newURL = "/azumayavietnam/take_list_member_ship/"+page+"/"+limit;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    })
};

export const takeListVoucherPushlic = (token) => {
    let newURL = "/azumayavietnam/take_list_voucher";
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    })
};

export const makeVoucherByAdmin = (type,data, token) => {
    let newURL = "/azumayavietnam/make_voucher_by_admin/" + type;
    return axios.post(`${newURL}`, data,{
        headers: { Authorization: token }
    })
};

export const editVoucherByAdmin = (type, data, id, token) => {
    let newURL = "/azumayavietnam/edit_voucher_by_admin/" + type +"/" + id;
    return axios.post(`${newURL}`, data,{
        headers: { Authorization: token }
    })
};

export const takeVoucherListByUserId = (id, token) => {
    let newURL = "/azumayavietnam/take_list_voucher_by_id/" + id;
    return axios.get(`${newURL}`,{
        headers: { Authorization: token }
    })    
};

export const findMemberShip = (id, token) => {
    let newURL = "/azumayavietnam/find_membership/" + id;
    return axios.get(`${newURL}`,{
        headers: { Authorization: token }
    })  
};

export const takeUserDataByID = (id, token) => {
    let newURL = "/azumayavietnam/take_data_user_by_id/" + id;
    return axios.get(`${newURL}`,{
        headers: { Authorization: token }
    })  
};
