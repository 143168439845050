import axios from 'axios';

export const takeAllContentAzVNByType = (type, token) => {
    let newURL = "/azumayavietnam/take_conent/" + type;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    })
};

export const loadContentAzVNByType = (language, target, token) => {
    let newURL = "/azumayavietnam/load_conent_by_target/"+ language + "/" + target;
    return axios.get(`${newURL}`, {
        headers: { Authorization: token }
    })
}