import React from "react";
import { changeDataToDD_MM_YYYY } from '../../../../utils/Date/SupportDate'
function ModalShowListVoucherMember(props) {
    const { data} = props;

    console.log("data", data)
    function renderListIitem() {
        let result = [];

        if(data.length > 0) {
            data.forEach(item => {
                result.push(<tr key={item._id}>
                    <td style={{fontSize:'1.2rem', border: '1px solid'}}>{item.code}</td>
                    <td style={{fontSize:'1.2rem', border: '1px solid'}}>{item.name}</td>
                    <td style={{fontSize:'1.2rem', border: '1px solid'}}>{changeDataToDD_MM_YYYY(item.expired)}</td>
                    <td style={{fontSize:'1.2rem', border: '1px solid'}}>{item.discount}</td>
                    <td style={{fontSize:'1.2rem', border: '1px solid'}}>{item.type}</td>
                </tr>)
            })
        }

        return result;
    }
    return (  <table>
        <thead>
          <tr>
            <th style={{fontSize:'1.2rem', border: '1px solid'}}>Code</th>
            <th style={{fontSize:'1.2rem', border: '1px solid'}}>Name</th>
            <th style={{fontSize:'1.2rem', border: '1px solid'}}>Expiry Date</th>
            <th style={{fontSize:'1.2rem', border: '1px solid'}}>Discount (%)</th>
            <th style={{fontSize:'1.2rem', border: '1px solid'}}>Type</th>
          </tr>
        </thead>
        <tbody>
          {data && renderListIitem()}
        </tbody>
      </table>)
}

export default ModalShowListVoucherMember;