import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';

import * as memberShip from '../../../../api/azumayavietnam/menbership';
import Modal2Button from '../../../../components/RCSumTable/ModalContent/Modal2Button';
import { actModalChange, actModalIsOk, actResetModal } from '../../../../actions/modal';

import './managerUser.css';

function UseVoucherByReception(props) {
    const dispatch = useDispatch();
    const [data, setData] = useState({
        userId: "",
        listSuggest: [],
        isSelect: false,
        searchVoucher: "",
        memberShip: {},
    });

    const token = useSelector(state => state.token);

    useEffect(() => {
        if("subSaveVoucher" in data.memberShip) {
            setData({...data, isSelect: true})
        }
    },[data.memberShip]);

    function onChangeDate(value, target, option) {
        const newData = {...data};

        if(target === "select") {
            // newData.memberShip = value;
            // newData.memberShip.subSaveVoucher = value.voucher;
            console.log("vo here")
            const loadDataUseById = async () => {
                const userData = await memberShip.takeUserDataByID(value._id, token);
                console.log("userData: ", userData.data.data)
                
                if(userData) {
                    console.log("vo here " )
                    newData.listSuggest = [];
                    newData.memberShip = userData.data.data;
                    newData.memberShip.subSaveVoucher = userData.data.data.voucher;
                    //newData.isSelect = true;
                   // setData(newData)
                }
            }

            loadDataUseById();

        } else if (target === "searchVoucher") {
            newData.searchVoucher = value;
            if(value === "") {
                newData.memberShip.voucher =  data.memberShip.subSaveVoucher;
            } else {
                newData.memberShip.voucher = data.memberShip.voucher.filter(item =>{
                    // return item.code.toLowerCase().includes(value.toLowerCase())
                    if(item.code.toLowerCase().includes(value.toLowerCase())) return item;
                 })
            }
        }
        
        else newData[target] = value;

        setData(newData)
    }

    function findMemberShip(value) {
        const loadData = async () => {
            const findMemberShip = await memberShip.findMemberShip(data.userId, token);
            console.log("findMemberShip", findMemberShip);

            onChangeDate(findMemberShip.data.data, "listSuggest")
        };

        loadData();
    }

    function showDataMemberShipAndVoucher() {
        console.log(" data.memberShip",  data.memberShip)
        let result = [];

        function showVoucher() {
            let result2 = [];

            if(data.memberShip.voucher.length === 0 ) {
                result2.push(<>
                   <h2 style={{color:'red', paddingTop:'12px'}}>Have Not Voucher</h2>
                   <img src="https://azumayavietnam.com/image/other/have_note_voucher.webp" 
                        width="300" height="233" alt="have_note_voucher"/>
                </>)
            } else {
                data.memberShip.voucher.forEach(item => {
                    result2.push(<div className="d-flex">
                        <div style={{border:'1px solid', width:'85%'}}>
                            <div style={{fontSize:"1.3rem", fontWeight:'600', textAlign:'left', paddingLeft:'36px'}}>Code: {item.code}</div>
                            <div style={{fontSize:"1.3rem", fontWeight:'600', textAlign:'left', paddingLeft:'36px'}}>Name: {item.name}</div>
                            <div style={{fontSize:"1.3rem", fontWeight:'600', textAlign:'left', paddingLeft:'36px'}}>Discount: {item.discount}</div>
                            <div style={{fontSize:"1.3rem", fontWeight:'600', textAlign:'left', paddingLeft:'36px'}}>expired: {item.expired}</div>
                        </div>
                        <button className="btn btn-primary m-2" onClick={() => showConfirmUseVoucher(item)}>Active Voucher</button>
                        </div>
                    )
                })
            }

            return result2;
        }

        if(data.isSelect) {
            result.push(<div className="d-flex">
                <div style={{border:'1px solid', width:'30%'}}>
                    <h2 className="op-primarycolor">MemberShip information</h2>
                    <div style={{fontSize:"1.5rem", fontWeight:'600', textAlign:'left', paddingLeft:'36px'}}>Name: {data.memberShip.name}</div>
                    <div style={{fontSize:"1.5rem", fontWeight:'600', textAlign:'left', paddingLeft:'36px', color: 'red'}}>User Id: {data.memberShip.userId}</div>
                    <div style={{fontSize:"1.5rem", fontWeight:'600', textAlign:'left', paddingLeft:'36px'}}>Email: {data.memberShip.email}</div>
                    <div style={{fontSize:"1.5rem", fontWeight:'600', textAlign:'left', paddingLeft:'36px'}}>Type Login: {data.memberShip.loginType}</div>
                    <div style={{fontSize:"1.5rem", fontWeight:'600', textAlign:'left', paddingLeft:'36px'}}
                                     className={(data.memberShip.rank === "platinum" || data.memberShip.rank === "diamond") ? "rainbow-text" 
                                                    : data.memberShip.rank === "gold" ? "op-yellowcolor" : ""}>Rank: {data.memberShip.rank}</div>
                </div>
                <div style={{border:'1px solid', width:'50%'}}>
                    <h2 className="op-primarycolor">List Voucher </h2>
                    <div  style={{height:'300px', overflow:'auto'}}>
                    <div>
                        <input value={data.searchVoucher} onChange={e => onChangeDate(e.target.value, "searchVoucher")}></input>
                        <button >Seach</button>
                    </div>
                        { showVoucher() }
                    </div>
                
                </div>
            </div>);

        }

        return result;
    }

    function showListSuggest() {
        if(data.listSuggest.length > 0) {

         return data.listSuggest.map((item, index) => (
                <div key={index} className="membership-list-suggest" style={{border:"1px solid", width:"600px", cursor: "pointer", textAlign:'left', paddingLeft: "24px", paddingTop:"12px", paddingBottom:"12px"}}
                        onClick={() => onChangeDate(item, "select")}>
                    <div style={{fontSize:"1.3rem", fontWeight:'600'}}>Name: {item.name}</div>
                    <div style={{fontSize:"1.3rem", fontWeight:'600'}}>Email: {item.email}</div>
                    <div style={{fontSize:"1.3rem", fontWeight:'600'}}>Type Login: {item.loginType}</div>
                    <div style={{fontSize:"1.3rem", fontWeight:'600'}}>ID {item.userId}</div>
                    <div style={{fontSize:"1.3rem", fontWeight:'600'}}>Rank: {item.rank}</div>
                </div>
            ));
        }
    }

    function showConfirmUseVoucher(data) {

        const changeModal = actModalChange("centered", "Confirm use Voucher: " + data.name + "(Code:" + data.code + ")", 
        <Modal2Button 
            buttonName1 = "Use"
            buttonName2 = "Close"
            styleButton1 = "btn-primary"
            styleButton2 = "btn-danger"
            onClickButton1 = {closeModal}
            onClickButton2 = {closeModal}
                />)
        dispatch(changeModal);
        const openModal = actModalIsOk();
        dispatch(openModal);
    }

    function closeModal() {
        const closeModal = actResetModal();
        dispatch(closeModal);
    }


    return (<div className="modal-show-voucher">
                <div className="form-container" style={{paddingBottom:'0px'}}>
                    <div className="form-group" style={{width:'500px'}}>
                        <label for="userId" style={{fontSize:'1.3rem'}}>MemberShip ID :</label>
                        <input type="text" id="userId" name="userId" placeholder="Please enter ID membership" required 
                            value={data.userId} onChange={e => onChangeDate(e.target.value, e.target.name)}/>
                        {data.userId !== "" && <button className="btn btn-primary"
                            onClick={() => findMemberShip()}>Seach</button>}
                    </div>
                </div>
                <>
                    {showListSuggest()}
                    {showDataMemberShipAndVoucher()}
                </>
             </div>)
};

export default UseVoucherByReception;