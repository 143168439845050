import React, { useEffect, useState } from 'react'
import { useTable, useGlobalFilter, useAsyncDebounce } from 'react-table'
import {changeDataToDD_MM_YYYY} from '../../../../utils/Date/SupportDate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";

// Create an editable cell renderer
const EditableCell = ({
    value: initialValue,
    row: { index },
    column: { id },
    onUpdateNoteToServer, // This is a custom function that we supplied to our table instance
}) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue)

    const onChange = e => {
        setValue(e.target.value)
    }

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
        onUpdateNoteToServer(index, value)
    }

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(initialValue)
    }, [initialValue])
    switch (id) {
        case "note": return <input value={value} onChange={onChange} onBlur={onBlur} />
        case "role": return <></>

        default: return <div>{value}</div>
    }

}

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
    Cell: EditableCell,
}

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <div className="d-flex align-items-center mb-2" style={{ margin: 'auto' }}>
            <h4 > Search:{' '}</h4>
            <input
                style={{ width: '300px' }}
                className="ml-2 search_input"
                value={value || ""}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder="Enter here key search"
            />
        </div>
    )
}

function Table({ columns, data, onActionButton ,typeShow, function1, function2, function3}) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        prepareRow,
    } = useTable({
        columns,
        data,
        typeShow,
        defaultColumn,

    },
        function1,
        function2,
        function3,
        useGlobalFilter)

    function capitalizeWords(str) {
        if(str) return str.replace(/\b\w/g, (char) => char.toUpperCase());
    }

    function renderTextWithHeader(header, cell, index) {
        switch (header) {
            case "No": {
                if(typeShow ==="member") return index + 1
                if(typeShow ==="voucher") return <div style={{display:'flex'}}>
                    <div style={{width:'30px', minWidth:'30px', maxWidth:'30px'}}>{index + 1}</div>
                    <FontAwesomeIcon icon={faEdit} style={{color:'green', cursor:'pointer'}}
                        onClick={() => function1(index)}/>
                    <FontAwesomeIcon icon={faTrash} style={{color:'red', cursor:'pointer', marginLeft:'4px'}}
                        onClick={() => function2(index)}/>
                {/* <button className='btn btn-primary'>Edit</button>
                <button className='btn btn-danger'>Disable</button> */}
            </div>

            }; 
            case "Az Coin": return cell.value.toLocaleString();break;
            case "Az Point": return cell.value ? cell.value.toLocaleString() : 0;break;
            case "Rank": return capitalizeWords(cell.value) ; break;
            case "Expiry": return cell.value ? changeDataToDD_MM_YYYY(cell.value) : 0
            case "Max Price": return cell.value ? cell.value.toLocaleString() : 0; 
            case "Voucher List": return <div style={{color:'blue', cursor:'pointer', textDecoration: 'underline'}}
                                                onClick={()=> function1(index)}>View List</div>

            default: return cell.render('Cell')
        }
    }

    function checkStyle(header) {
        if(header ==="No" && typeShow === "voucher") {
            return {
                width: '80px',
                minWidth: '80px',
                maxWidth: '80px',
            }
        }
    }

    // Render the UI for your table
    return (
        <>
            <div className='d-flex flex-row bd-highlight'>
                <div className='p-2 bd-highlight"'>
                    <GlobalFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter} />
                </div>
                {(typeShow === "voucher" || typeShow === "member")
                    && <button className='btn btn-primary p-2 bd-highlight' style={{height:'30px'}} 
                               onClick={() => function3(2)}
                        >Add New Voucher</button>}
            </div>


            <table {...getTableProps()}
                style={{ margin: 'auto' }}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}
                                    style={checkStyle(column.Header)}
                                    className={column.classStyle ? column.classStyle : ""}
                                >{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell, index) => {
                                    return <td {...cell.getCellProps()}
                                        className={(cell.column.Header === "Rank" ) ?
                                        (cell.value==="diamond" || cell.value==="platinum" )? "rainbow-text" :
                                        cell.value==="gold" ? "op-yellowcolor" : "" :""}
                                    >{ renderTextWithHeader(cell.column.Header, cell, i) }
                                        {/* {(cell.column.Header === "Active")
                                            ? (<h4
                                                style={{
                                                    fontSize: '1.1rem',
                                                    color: 'red',
                                                    cursor: 'pointer'
                                                }}
                                                className={(!data[i].log ? "" :
                                                    data[i].log.active >= 3 ? "op-hidden" : "") + " ml-2"}
                                                onClick={() => onActionButton(row.original)}
                                            >
                                                (Action ?)
                                            </h4>) : ""}
                                        {(cell.column.Header === "No")
                                            ? (i + 1) : ""}
                                        {(cell.column.Header === "Role")
                                            ? renderTextWithRole(cell.value) : ""} */}
                                    </td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </>
    )
}

function TableShowMemberShipUser(props) {
    const { typeShow, } = props;
    //console.log("props TableShowMemberShipUser", props)

    const takeListRenderColumns = {
        member : [
            {
                Header: 'No',
                accessor: 'no',
                classStyle: 'rp__size-xs bg_orange_blur_color'
            },
            {
                Header: 'ID',
                accessor: 'userId',
                classStyle: 'rp__size-xs bg_orange_blur_color'
            },
            {
                Header: 'Name',
                accessor: 'name',
                classStyle: 'rp__size-xl bg_orange_blur_color'
            },
            {
                Header: 'Email',
                accessor: 'email',
                classStyle: 'rp__size-xm bg_orange_blur_color'
    
            },
            {
                Header: 'Login Type',
                accessor: 'loginType',
                classStyle: 'rp__size-xs bg_orange_blur_color'
            },
            {
                Header: 'Voucher List',
                accessor: 'voucher',
                classStyle: 'rp__size-xs bg_orange_blur_color'
            },
            {
                Header: 'Az Point',
                accessor: 'azPoint',
                classStyle: 'rp__size-xs bg_orange_blur_color'
            },
            {
                Header: 'Az Coin',
                accessor: 'azCoin',
                classStyle: 'rp__size-xs bg_orange_blur_color'
            },
            {
                Header: 'Rank',
                accessor: 'rank',
                classStyle: 'rp__size-xs bg_orange_blur_color'
            },
            // {
            //     Header: 'Active',
            //     accessor: 'active',
            //     classStyle: 'rp__size-xl bg_orange_blur_color'
            // }
        ],
        voucher: [
            {
                Header: 'No',
                accessor: 'no',
                classStyle: 'rp__size-xs bg_orange_blur_color'
            },
            {
                Header: 'Code',
                accessor: 'code',
                classStyle: 'rp__size-xs bg_orange_blur_color'
            },
            {
                Header: 'Name',
                accessor: 'name',
                classStyle: 'rp__size-xl bg_orange_blur_color'
            },
            {
                Header: 'Type',
                accessor: 'type',
                classStyle: 'rp__size-xs bg_orange_blur_color'
            },
            {
                Header: 'Max Price',
                accessor: 'maxPrice',
                classStyle: 'rp__size-xs bg_orange_blur_color'
            },
            {
                Header: 'Expiry',
                accessor: 'expiry',
                classStyle: 'rp__size-xs bg_orange_blur_color'
            },
        ]
    }

    const data = props.data

    function onActionButton(item) {
        props.onOpenActionModal(item);
    }

    function function1(index) {
        props.function1(index)
    }

    function function2(index) {
        props.function2(index)
    }

    function function3(index) {
        props.function3(index)
    }
    return (
        <Table
            //columns={columns}
            columns={takeListRenderColumns[typeShow]}
            data={data}
            typeShow={typeShow}
            onActionButton={onActionButton}
            function1={function1}
            function2={function2}
            function3={function3}
        />
    )
}

export default TableShowMemberShipUser
