import React, { useEffect, useState } from "react";
import './modal.css'
function ModalShowNewVoucher(props) {

    const [data,setData] = useState({
        id: "",
        name: "",
        code: "",
        type: "Change",
        discount: 0,
        maxPrice: 0,
        azCoin: 0,
        infor: "",
        expiry: new Date(),
    })

    const [edit, setEdit] = useState(false);
    const [typeMake, setTypeMake] = useState("id");
    const { selectMenu }= props;

    console.log("selectMenu", selectMenu)
    useEffect(() => {
        if(props.data) {
            setData(props.data);
            setEdit(true);
        }
    },[]);

    function onChangeDate(value, target, option) {
        const newData = {...data};
        newData[target] = value;
        setData(newData)
    }

    function onSubmit(event) {
        event.preventDefault();
        props.featureSupport1(data)
    }
    return (<div className="modal-show-voucher">
                <div className="form-container">
                    <form onSubmit={onSubmit}>
                        {
                           selectMenu ==="member" && <div className="form-group">
                            <label for="name">Type Make Voucher</label>
                            <button className={"btn " + (typeMake ==="id" ? "btn-primary" : "")} onClick={() => setTypeMake("id")}>By ID</button>
                            <button className={"btn " + (typeMake !=="id" ? "btn-primary" : "")} onClick={() => setTypeMake("typeLogin")}>By Type Login</button>
                        </div> 
                        }
                        {
                           (selectMenu ==="member" && typeMake === "id") && <div className="form-group">
                            <label for="id">ID</label>
                            <input type="text" id="id" name="id" placeholder="Enter ID make voucher" required 
                                value={data.name} onChange={e => onChangeDate(e.target.value, e.target.name)}/>
                          </div>
                        }
                        {
                           (selectMenu ==="member" && typeMake === "typeLogin") && <div className="form-group">
                            <label for="typeLogin">Type Login</label>
                            <select id="typeLogin" name="typeLogin">
                            <option value="email">Email</option>
                            <option value="google">Google</option>
                            <option value="line">Line</option>
                            <option value="facebook">FaceBook</option>
                            </select>
                        </div>
                        }
                        <div className="form-group">
                            <label for="name">Name</label>
                            <input type="text" id="name" name="name" placeholder="Enter voucher name" required 
                                value={data.name} onChange={e => onChangeDate(e.target.value, e.target.name)}/>
                        </div>
                        <div className="form-group">
                            <label for="code">Code</label>
                            <input type="text" id="code" name="code" placeholder="Enter code" required 
                                value={data.code} onChange={e => onChangeDate(e.target.value, e.target.name)}/>
                        </div>
                        <div className="form-group">
                            <label for="type">Type</label>
                            <select id="type" name="type">
                            <option value="Change">Change</option>
                            <option value="Other">Other</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label for="discount">Discount (%)</label>
                            <input type="number" id="discount" name="discount" placeholder="Enter discount percentage" required 
                                value={data.discount} onChange={e => onChangeDate(e.target.value, e.target.name)}/>
                        </div>
                        <div className="form-group">
                            <label for="maxPrice">Max Price Discount</label>
                            <input type="number" id="maxPrice" name="maxPrice" placeholder="Enter max price" required 
                                value={data.maxPrice} onChange={e => onChangeDate(e.target.value, e.target.name)}/>
                        </div>
                        <div className="form-group">
                            <label for="azCoin">AzCoin Request</label>
                            <input type="number" id="azCoin" name="azCoin" placeholder="Enter AzCoin value" required 
                                value={data.azCoin} onChange={e => onChangeDate(e.target.value, e.target.name)}/>
                        </div>
                        <div className="form-group">
                            <label for="infor">Information</label>
                            <textarea id="infor" name="infor" rows="3" placeholder="Enter additional information"
                            value={data.infor} onChange={e => onChangeDate(e.target.value, e.target.name)}></textarea>
                        </div>
                        <div className="form-group">
                            <label for="expiry">Expiry Date</label>
                            <input type="date" id="expiry" name="expiry" required 
                                value={data.expiry} onChange={e => onChangeDate(e.target.value, e.target.name)}/>
                        </div>
                        <div className="form-group">
                            <button type="submit" className="sumit-button">{edit ? "Change Edit" :"Add New Voucher"}</button>
                        </div>
                    </form>
                </div>
            </div>)
}

export default ModalShowNewVoucher;