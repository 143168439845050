import React from "react";

function ManSumShowSmallModalDetails(props) { 
    const { data } = props;
    
    function renderTab() {
        let result = [];

        for (let i =0; i < data.target; i++) {
            result.push(<tr>
                <td>{i+1}</td>
                <td type="number" style={{ width:"20px" }}><input></input></td>
                <td type="text"><input></input></td>
            </tr>)
        }

        return result;
    }
    return (
        data.target === 0 ? "" 
        :<table style={{border: '1px solid',padding: '10px',boxShadow: '5px 10px #888888'}}>
              <tr>
                <th>No.</th>
                <th>Disscount</th>
                <th>Note</th>
              </tr>
             {renderTab()}
       </table>)
}

export default ManSumShowSmallModalDetails;